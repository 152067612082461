import React from 'react'
import Button from '@mui/material/Button'
import { trackEvent } from '../../utils/ArtboxoneTracking'
import RateReviewIcon from '@mui/icons-material/RateReview'
import Nps from '../artboxone/Nps'

export interface NpsButtonProps {
  orderId: number
}

export default function NpsButton(props: NpsButtonProps) {
  const [showForm, setShowForm] = React.useState(false)

  const handleClick = () => {
    trackEvent({
      category: 'OrdersPage',
      action: 'Button_Nps',
      label: 'Open_Nps_Form',
    })

    setShowForm(!showForm)
  }

  return (
    <div className="row">
      <div className="col-md-12">
        {showForm && <Nps orderId={props.orderId} />}
        {!showForm && (
          <Button color="secondary" onClick={handleClick} variant="contained">
            <RateReviewIcon /> Bewertungsformular aufrufen
          </Button>
        )}
      </div>
    </div>
  )
}
