import {env} from '../../../environment'

export interface FetchOrderStatusOptions {
  orderId: number
  mailAddress: string
}

export const fetchOrderStatusData = (options: FetchOrderStatusOptions) => {
  const url =
    env.getServiceHost() +
    'order/orderstatus?orderId=' +
    encodeURIComponent(options.orderId) +
    '&mailAddress=' +
    encodeURIComponent(options.mailAddress)

  return fetch(url).then((response) => response.json())
}
