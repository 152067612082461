import { useState, useEffect } from 'react'
import { fetchOrderStatusData, FetchOrderStatusOptions } from './orderstatus_api'

export interface OrderStatusData {
  paid: boolean
  transfer: string
  status_p: string
  status_s: string
  post: string
}


export const useOrderStatusData = (options: FetchOrderStatusOptions) => {
  const [orderStatusData, setOrderStatusData] = useState<undefined | OrderStatusData | string>(undefined)

  useEffect(() => {
    fetchOrderStatusData(options).then(response => {
      setOrderStatusData(response.data)
    })
  }, [options.orderId + '-' + options.mailAddress])

  return orderStatusData
}
